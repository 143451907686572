
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { retryWhen, delay, mergeMap } from "rxjs/operators";
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_BACKOFF = 1000;

export function delayedRetry(delayMs: number, maxRetries: number = DEFAULT_MAX_RETRIES, backoffMs = DEFAULT_BACKOFF) {

  let retries = maxRetries;
  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        mergeMap(error => {
          if (retries-- > 0) {
            const backoffTime = delayMs + (maxRetries - retries) * backoffMs;
            return of(error)
              .pipe(delay(backoffTime));
          }
          return observableThrowError(error);
        })
      ))
    );
}


export class Filter {
  name: string;
  value: number | string | string[];

  constructor(name: string, value: number | string | string[]) {
    this.name = name;
    this.value = value;
  }

  public getFilterValues(): Filter[] {
    const values = Array.isArray(this.value) ? this.value : [ this.value ];
    return values.map(value => new Filter(this.name, value));
  }
}

export function patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
  return (control: AbstractControl): { [ key: string ]: any; } => {
    if (!control.value) {
      return null;
    }
    const valid = regex.test(control.value);
    return valid ? null : error;
  };
}

export function matchPassword(password: string, confirmPassword: string) {
  return (formGroup: FormGroup) => {
    const passwordControl = formGroup.controls[ password ];
    const confirmPasswordControl = formGroup.controls[ confirmPassword ];

    if (!passwordControl || !confirmPasswordControl) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
    } else {
      confirmPasswordControl.setErrors(null);
    }
    return null;
  };
}

export function deleteSpaces(word: string) {
  return word.split(/\s+/).join('');
}
